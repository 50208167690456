import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { isUnlimitedPlan, isRecurringPlan, isFreePlan } from '@wix/pricing-plans-utils';
import { TFunction } from '@wix/yoshi-flow-editor';
import { NO_BREAK_SPACE } from '../constants';

export const getPlanValidityCycle = (plan: PublicPlan, t: TFunction) => {
  if (!plan.pricing) {
    return NO_BREAK_SPACE;
  }

  const isUnlimited = isUnlimitedPlan(plan);

  if (isUnlimited && isFreePlan(plan)) {
    return t('pp.plan-validity.free-plan');
  }

  if (!isUnlimited) {
    return getPlanDurationWithoutUnlimited(plan, t);
  }

  return NO_BREAK_SPACE;
};

export const getPlanDuration = (plan: PublicPlan, t: TFunction) => {
  if (isUnlimitedPlan(plan)) {
    return t('pp.plan-validity.valid-unlimited');
  } else {
    return getPlanDurationWithoutUnlimited(plan, t);
  }
};

const getPlanDurationWithoutUnlimited = (plan: PublicPlan, t: TFunction) => {
  let unit = plan.pricing?.singlePaymentForDuration?.unit;
  let planDuration = plan.pricing?.singlePaymentForDuration?.count || 0;
  if (isRecurringPlan(plan)) {
    unit = plan.pricing?.subscription?.cycleDuration?.unit;
    planDuration =
      (plan.pricing?.subscription?.cycleCount || 0) * (plan.pricing?.subscription?.cycleDuration?.count || 0);
  }

  switch (unit) {
    case PeriodUnit.DAY:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-days', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-day');
    case PeriodUnit.WEEK:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-weeks', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-week');
    case PeriodUnit.YEAR:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-years', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-year');
    default:
      return planDuration > 1
        ? t('pp.plan-validity.valid-for-months', { amount: planDuration })
        : t('pp.plan-validity.valid-for-one-month');
  }
};
