import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'SinglePlanWidget',
  props: {
    planId: {
      type: WidgetPropertyType.STRING,
    },
  },
  methods: {
    onSelect: {
      params: [
        {
          name: 'callback',
          description: '(plan: PublicPlan) => void',
        },
      ],
    },
    setPlan: {
      params: [{ name: 'planId' }],
    },
  },
  events: {},
});
