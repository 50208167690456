import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TFunction } from '@wix/yoshi-flow-editor';

export const getFreeTrialDaysLabel = (plan: PublicPlan, t: TFunction) => {
  if (!plan.pricing?.freeTrialDays) {
    return '';
  }

  if (plan.pricing?.singlePaymentUnlimited || plan.pricing?.subscription?.cycleCount === 0) {
    return t('pp.free-trial-days-unlimited', { count: plan.pricing?.freeTrialDays });
  }

  return t('pp.free-trial-days', { count: plan.pricing?.freeTrialDays });
};
