import { getGlobalObject } from './get-global-object';

export function getSiteBrand() {
  const globalObj = getGlobalObject();
  if (globalObj?.commonConfig) {
    return globalObj.commonConfig.brand || 'wix';
  }

  return 'wix';
}
