import { retry } from '@wix/pricing-plans-utils/http';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { isWixHttpError } from './errors';

export async function retry401<T>(
  wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
  action: () => Promise<T>,
) {
  return retry(
    {
      times: 1,
      delay: 20,
      refresh: () => wixCodeApi.site.loadNewSession(),
      cancelRetryIf(e) {
        return !isWixHttpError(e) || e.response?.status !== 401;
      },
    },
    action,
  );
}
