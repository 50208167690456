export enum SinglePlanInteractions {
  SetPlanData = 'set_plan_data',
  InitializeInEditor = 'es_single_plan_blocks_initialize_in_editor',
  GetBaseManifest = 'es_single_plan_blocks_get_base_manifest',
  GetPricingPlansApi = 'get_pricing_plans_api',
  AddWidget = 'es_single_plan_blocks_add',
  AssertWidgetExists = 'es_single_plan_blocks_assert_exists',
  SetInitialPresets = 'es_single_plan_blocks_set_initial_presets',
  SetInitialInnerWidgetState = 'es_single_plan_blocks_set_initial_inner_widget_state',
  SetInitialPlan = 'es_single_plan_blocks_set_initial_plan',
}
