import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Plan',
  props: {},
  methods: {
    setPlan: {
      params: [
        {
          name: 'plan',
          description: 'PublicPlan object',
        },
      ],
    },
    onSelect: {
      params: [
        {
          name: 'callback',
          description: '(plan: PublicPlan) => void',
        },
      ],
    },
    registerCtaHandler: {
      params: [
        {
          name: 'plan',
          description: ' PublicPlan object',
        },
        {
          name: 'widgetType',
          description: 'string, indicating which widget controls the inner plan widget',
        },
      ],
    },
  },
  events: {},
});
