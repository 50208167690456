import { PerksWidgetRole } from '@wix/pricing-plans-common/blocks';
import model from './model';

export const ElementId = {
  PerksContainer: `#${PerksWidgetRole.PerksContainer}`,
  PerkText: `#${PerksWidgetRole.PerkText}`,
  PerkDivider: `#${PerksWidgetRole.PerkDivider}`,
} as const;

export default model.createController(({ $w }) => {
  return {
    pageReady: async () => {},
    exports: {
      setPerks: (perks: string[]) => {
        $w(ElementId.PerksContainer).onItemReady(($item, itemData, i) => {
          $item(ElementId.PerkText).text = itemData.text;
          if (i === perks.length - 1) {
            $item(ElementId.PerkDivider).collapse();
          }
        });

        $w(ElementId.PerksContainer).data = perks.map((perk, i) => ({
          _id: 'perk-' + i,
          text: perk,
        }));
      },
    },
  };
});
