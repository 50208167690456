import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Pricing',
  props: {},
  methods: {
    setPricing: {
      params: [
        {
          name: 'data',
          description: '{ price: string; currency: string; paymentFrequency: string; setupFee: string; }',
        },
        { name: 'elementOptions', description: '{ forceExpandFrequency: string; forceExpandSetupFee: string}' },
      ],
    },
  },
  events: {},
});
