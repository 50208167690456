import { createBlocksModel, ExtractWidgetProps, Prop, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

const props = {
  planIds: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
  },
  isPlanList: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  customStylePlanIds: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
  },
  highlightedPlanIds: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
    // TODO: Uncomment when https://jira.wixpress.com/browse/PPNP-1637 is done
    // defaultValue: HIGHLIGHTED_MOCK_PLAN_ID,
  },
  isHorizontal: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
  },
} satisfies Record<string, Prop>;

export default createBlocksModel({
  widgetName: 'PlanListWidget',
  props,
  methods: {
    onSelect: {
      params: [
        {
          name: 'callback',
          description: '(plan: PublicPlan) => void',
        },
      ],
    },
    setPlans: {
      params: [{ name: 'planIds' }],
    },
    setTitle: {
      params: [{ name: 'title' }],
    },
    setSubtitle: {
      params: [{ name: 'subtitle' }],
    },
  },
  events: {},
});

export type PlanListWidgetProps = ExtractWidgetProps<typeof props>;
