import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Benefits',
  props: {},
  methods: {
    setPerks: {
      params: [
        {
          name: 'perks',
          description: 'string[] | undefined',
        },
      ],
    },
  },
  events: {},
});
