type SiteBrand = 'wix' | 'editorx' | 'studio';

type GlobalWithCommonConfig = typeof globalThis & { commonConfig?: { brand?: SiteBrand } };

export function getGlobalObject(): GlobalWithCommonConfig | undefined {
  if (typeof globalThis === 'object') {
    return globalThis;
  } else if (typeof self === 'object') {
    return self;
  } else if (typeof global === 'object') {
    return global;
  }
}
